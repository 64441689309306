import React from 'react';
// import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
// import routerLinks from '../../components/app/routerLinks';
import Logo from '../../common/logo/Logo';
import SigninForm from './SigninForm';
import './SigninPage.scss';

const SigninPage = () => {
  return (
    <div className="shared-custom-page signin-page">
      {/* <CustomBreadcrumb
        arr={[
          {
            title: t('breadcrumb_section.home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: t('breadcrumb_section.signin'),
            isLink: false
          }
        ]}
      /> */}
      <div className="custom-container">
        <section className="signin-form-section">
          <Logo className="logo" />
          <SigninForm />

          <div className="copyrights">
            <p>Naomy Copyright © 2025 all rights reserved</p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SigninPage;
