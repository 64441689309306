/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Rate } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import queryString from 'query-string';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import AntdCheckboxsGroup from '../../common/antd-form-components/AntdCheckboxsGroup';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import discountOfferFilterArr from '../../const-values/discountOffersFilterArr';
import filterTypes from '../../const-values/filterTypes';
import { paymentTypesFilter } from '../../const-values/paymentTypes';
import productSettingsOptions from '../../const-values/productSettingsOptions';
import rateArr from '../../const-values/rateArr';
import GeneralProductsContext from '../../contexts/general-products-context/GeneralProductsContext';
import './GeneralProductsFilter.scss';
import {
  handleNavigateAll,
  handleNavigateSingleFilter
} from './handleNavigateCheckboxsFilter';

const GeneralproductsFilter = ({
  allFetchedCats,
  allFetchedFilterCats,
  allFetchedCountries,
  allFetchedPriceFilter
}) => {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { setTableFilter } = useContext(GeneralProductsContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const schema = Yup.object().shape({
    // loginKey: Yup.string()
    //   .required('من فضلك ادخل البريد الاكترونى')
    //   .email('ادخل بريد الكترونى صحيح'),
  });
  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      country_id: [],
      city_id: [],
      catFilter_id: [],
      delivery: []
    }
  });

  // console.log('tabfil : ', tableFilter);

  // const onSubmit = (data) => {
  //     signMeInWithEmailPassword({
  //       // email: data.loginKey,
  //       ...data
  //     });
  //   };

  const checkBoxsArr = (arr) => {
    let items = arr.map((item) => {
      return {
        label: item?.name,
        value: item?.id
      };
    });
    return [
      // {
      //   label: 'all',
      //   value: 'all'
      // },
      ...items
    ];
  };

  useEffect(() => {
    if (
      watch('country_id')?.length >= 0 ||
      watch('city_id')?.length >= 0 ||
      watch('catFilter_id')?.length >= 0 ||
      watch('delivery')?.length >= 0
    ) {
      setTableFilter((prev) => {
        return {
          ...prev,
          store_id: values?.store_id || '',
          country_id: {
            ...prev?.country_id,
            value: watch('country_id') || []
          },
          city_id: {
            ...prev?.city_id,
            value: watch('city_id') || []
          },
          cat_id: {
            ...prev?.cat_id,
            value: values?.cat_id || ''
          },
          catFilter_id: {
            ...prev?.catFilter_id,
            value: watch('catFilter_id') || []
          },
          discount: {
            ...prev?.discount,
            value: values?.discount || ''
          },
          delivery: {
            ...prev?.delivery,
            value: watch('delivery') || []
          },
          rate: {
            ...prev?.rate,
            value: values?.rate || ''
          },
          fromPrice: {
            ...prev?.fromPrice,
            value: values?.fromPrice || ''
          },
          toPrice: {
            ...prev?.toPrice,
            value: values?.toPrice || ''
          },
          paymentType: {
            ...prev?.paymentType,
            value: values?.paymentType || ''
          },
          sort: {
            ...prev?.sort,
            value: values?.sort || ''
          }
        };
      });

      // navigate(
      //   `${generalRouterLinks?.generalProductsRoute}?store_id=${
      //     values?.store_id || ''
      //   }&country_id=${
      //     (watch('country_id')?.length > 0 && watch('country_id')) || ''
      //   }&cat_id=${values?.cat_id || ''}&catFilter_id=${
      //     (watch('catFilter_id')?.length > 0 && watch('catFilter_id')) || ''
      //   }&discount=${values?.discount || ''}&delivery= ${
      //     (watch('delivery')?.length > 0 && watch('delivery')) || ''
      //   }&rate=${values?.rate || ''}&fromPrice=${
      //     values?.fromPrice || ''
      //   }&toPrice=${values?.toPrice || ''}&paymentType=${
      //     values?.paymentType || ''
      //   }&sort=${values?.sort || ''}&page=${values?.page || 1}`
      // );
    }
  }, [watch('country_id'), watch('catFilter_id'), watch('delivery'), search]);

  // handle initial values
  useEffect(() => {
    // if (values?.country_id || values?.catFilter_id || values?.delivery) {
    setValue(
      'country_id',
      values?.country_id
        ? values?.country_id.split(',')?.length > 0 &&
            values.country_id.split(',').map((item) => parseInt(item))
        : []
    );
    setValue(
      'city_id',
      values?.city_id
        ? values?.city_id.split(',')?.length > 0 &&
            values.city_id.split(',').map((item) => parseInt(item))
        : []
    );
    setValue(
      'catFilter_id',
      values?.catFilter_id
        ? values?.catFilter_id.split(',')?.length > 0 &&
            values.catFilter_id.split(',').map((item) => parseInt(item))
        : []
    );
    setValue(
      'delivery',
      values?.delivery
        ? values?.delivery.split(',')?.length > 0 &&
            values.delivery.split(',').map((item) => item)
        : []
    );
    // }
  }, [
    values?.country_id,
    values?.city_id,
    values?.catFilter_id,
    values?.delivery
  ]);

  const renderCheckboxsFilter = (arr, name, title, className) => {
    return (
      <div className={`shared-filter-section ${className}`}>
        <div className="section-title bold-body-font">{title}</div>
        <div className="section-checkboxs-wrap">
          <Checkbox
            className="check-all-checkbox"
            onChange={(e) => {
              if (e.target.checked) {
                setValue(
                  name,
                  checkBoxsArr(arr).map((item) => item.value)
                );
              } else setValue(name, []);
              handleNavigateAll(e.target.checked, name, arr, navigate, values);
            }}
            checked={watch(name)?.length === checkBoxsArr(arr)?.length}
          >
            {t('productsPage.checkAll')}
          </Checkbox>

          <AntdCheckboxsGroup
            name={name}
            // label="label here"
            className="general-products-filter-form"
            // onChange={() => setValue('model_id', '')}
            onChange={(v) => {
              handleNavigateSingleFilter(name, v, navigate, values);
            }}
            control={control}
            validateStatus={errors?.name ? 'error' : ''}
            errorMsg={errors?.name?.message}
            options={arr?.length > 0 ? [...checkBoxsArr(arr)] : []}
            // defaultValue={
            //   values?.airline_id ? values?.airline_id.split(',') : []
            // }
          />
        </div>
      </div>
    );
  };

  const renderCatsFilter = () => {
    return (
      <div className="shared-filter-section cats-section btns-section">
        <div className="section-title">{t('productsPage.categories')}</div>
        {allFetchedCats?.length > 0 && (
          <div className="btns-wrap">
            {allFetchedCats.map((cat) => {
              return (
                <RouterLink
                  className={values?.cat_id == cat?.id ? 'active-btn' : ''}
                  to={`${generalRouterLinks?.generalProductsRoute}?store_id=${
                    values?.store_id || ''
                  }&country_id=${values?.country_id || ''}&city_id=${
                    values?.city_id || ''
                  }&cat_id=${
                    values?.cat_id == cat?.id ? '' : cat?.id || ''
                  }&catFilter_id=${values?.catFilter_id || ''}&discount=${
                    values?.discount || ''
                  }&delivery=${values?.delivery || ''}&rate=${
                    values?.rate || ''
                  }&fromPrice=${values?.fromPrice || ''}&toPrice=${
                    values?.toPrice || ''
                  }&paymentType=${values?.paymentType || ''}&sort=${
                    values?.sort || ''
                  }&page=${values?.page || 1}`}
                  onClick={() => {
                    setTableFilter((prev) => {
                      if (prev?.cat_id == cat?.id) {
                        return {
                          ...prev,
                          store_id: values?.store_id || '',
                          page: 1,
                          cat_id: {
                            ...prev?.cat_id,
                            value: ''
                          }
                        };
                      }
                      return {
                        ...prev,
                        store_id: values?.store_id || '',
                        page: 1,
                        cat_id: {
                          ...prev?.cat_id,
                          value: cat?.id
                        }
                      };
                    });
                  }}
                  key={cat?.id}
                >
                  {cat?.name}
                </RouterLink>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderDiscountFilter = () => {
    return (
      <div className="shared-filter-section cats-section btns-section">
        <div className="section-title">{t('productsPage.discount')}</div>
        {discountOfferFilterArr(t)?.length > 0 && (
          <div className="btns-wrap">
            {discountOfferFilterArr(t).map((item) => {
              return (
                <RouterLink
                  className={values?.discount == item?.id ? 'active-btn' : ''}
                  to={`${generalRouterLinks?.generalProductsRoute}?store_id=${
                    values?.store_id || ''
                  }&country_id=${values?.country_id || ''}&city_id=${
                    values?.city_id || ''
                  }&cat_id=${values?.cat_id || ''}&catFilter_id=${
                    values?.catFilter_id || ''
                  }&discount=${
                    values?.discount == item?.value ? '' : item?.value || ''
                  }&delivery=${values?.delivery || ''}&rate=${
                    values?.rate || ''
                  }&fromPrice=${values?.fromPrice || ''}&toPrice=${
                    values?.toPrice || ''
                  }&paymentType=${values?.paymentType || ''}&sort=${
                    values?.sort || ''
                  }&page=${values?.page || 1}`}
                  onClick={() => {
                    setTableFilter((prev) => {
                      if (prev?.discount == item?.id) {
                        return {
                          ...prev,
                          store_id: values?.store_id || '',
                          discount: {
                            ...prev?.discount,
                            value: ''
                          },
                          page: 1
                        };
                      }
                      return {
                        ...prev,
                        store_id: values?.store_id || '',
                        discount: {
                          ...prev?.discount,
                          value: item?.value
                        },
                        page: 1
                      };
                    });
                  }}
                  key={item?.id}
                >
                  {item?.name}
                </RouterLink>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderRateFilter = () => {
    return (
      <div className="shared-filter-section rate-section btns-section">
        <div className="section-title">{t('productsPage.rate')}</div>
        {rateArr?.length > 0 && (
          <div className="btns-wrap">
            {rateArr.map((obj) => {
              return (
                <RouterLink
                  className={values?.rate == obj?.rate ? 'active-rate-btn' : ''}
                  to={`${generalRouterLinks?.generalProductsRoute}?store_id=${
                    values?.store_id || ''
                  }&country_id=${values?.country_id || ''}&city_id=${
                    values?.city_id || ''
                  }&cat_id=${values?.cat_id || ''}&catFilter_id=${
                    values?.catFilter_id || ''
                  }&discount=${values?.discount || ''}&delivery=${
                    values?.delivery || ''
                  }&rate=${
                    values?.rate == obj?.rate ? '' : obj?.rate || ''
                  }&fromPrice=${values?.fromPrice || ''}&toPrice=${
                    values?.toPrice || ''
                  }&paymentType=${values?.paymentType || ''}&sort=${
                    values?.sort || ''
                  }&page=${values?.page || 1}`}
                  onClick={() => {
                    setTableFilter((prev) => {
                      if (prev?.rate == obj?.rate) {
                        return {
                          ...prev,
                          store_id: values?.store_id || '',
                          rate: {
                            ...prev?.rate,
                            value: ''
                          },
                          page: 1
                        };
                      }
                      return {
                        ...prev,
                        store_id: values?.store_id || '',
                        rate: {
                          ...prev?.rate,
                          value: obj?.rate
                        },
                        page: 1
                      };
                    });
                  }}
                  key={obj?.id}
                >
                  <Rate disabled defaultValue={obj?.rate} />
                </RouterLink>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderPricesFilter = () => {
    return (
      <div className="shared-filter-section price-section btns-section">
        <div className="section-title">{t('productsPage.priceRange')}</div>
        {allFetchedPriceFilter?.length > 0 && (
          <div className="btns-wrap">
            {allFetchedPriceFilter.map((obj) => {
              return (
                <RouterLink
                  className={
                    values?.fromPrice == obj?.fromPrice &&
                    values?.toPrice == obj.toPrice
                      ? 'active-btn'
                      : ''
                  }
                  to={`${generalRouterLinks?.generalProductsRoute}?store_id=${
                    values?.store_id || ''
                  }&country_id=${values?.country_id || ''}&city_id=${
                    values?.city_id || ''
                  }&cat_id=${values?.cat_id || ''}&catFilter_id=${
                    values?.catFilter_id || ''
                  }&discount=${values?.discount || ''}&delivery=${
                    values?.delivery || ''
                  }&rate=${values?.rate || ''}&fromPrice=${
                    values?.fromPrice && obj?.fromPrice == values?.fromPrice
                      ? ''
                      : obj?.fromPrice || ''
                  }&toPrice=${
                    values?.toPrice && obj?.toPrice == values?.toPrice
                      ? ''
                      : obj?.toPrice || ''
                  }&paymentType=${values?.paymentType || ''}&sort=${
                    values?.sort || ''
                  }&page=${values?.page || 1}`}
                  onClick={() => {
                    setTableFilter((prev) => {
                      if (
                        prev?.fromPrice == obj?.fromPrice &&
                        prev?.toPrice == obj?.toPrice
                      ) {
                        return {
                          ...prev,
                          store_id: values?.store_id || '',
                          fromPrice: {
                            ...prev?.fromPrice,
                            value: ''
                          },
                          toPrice: {
                            ...prev?.toPrice,
                            value: ''
                          },
                          page: 1
                        };
                      }

                      return {
                        ...prev,
                        store_id: values?.store_id || '',
                        fromPrice: {
                          ...prev?.fromPrice,
                          value: obj?.fromPrice
                        },
                        toPrice: {
                          ...prev?.toPrice,
                          value: obj?.toPrice
                        },
                        page: 1
                      };
                    });
                  }}
                  key={obj?.id}
                >
                  <span>
                    {obj?.fromPrice || '0'} ${t('currency.sar')}
                  </span>
                  <span> - </span>
                  <span>
                    {obj?.toPrice == 0
                      ? '+'
                      : `${obj.toPrice}  ${t('currency.sar')}`}
                  </span>
                </RouterLink>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const [form] = Form.useForm();
  return (
    <div className="general-products-filter">
      <Form
        className="general-products-filter-form"
        form={form}
        layout="vertical"
        // onFinish={handleSubmit(onSubmit)}
        initialValues={{
          country_id: watch('country_id'),
          city_id: watch('city_id'),
          catFilter_id: watch('catFilter_id'),
          delivery: watch('delivery')
        }}
        fields={[
          {
            name: 'country_id',
            value: watch('country_id')
          },
          {
            name: 'city_id',
            value: watch('city_id')
          },
          {
            name: 'catFilter_id',
            value: watch('catFilter_id')
          },
          {
            name: 'delivery',
            value: watch('delivery')
          }
        ]}
      >
        <div className="form-body">
          {/* {allFetchedCountries?.length > 0 &&
            renderCheckboxsFilter(
              allFetchedCountries,
              filterTypes?.country_id,
              t('productsPage.countries'),
              'countries-section'
            )} */}
          {allFetchedCountries?.length > 0 && (
            <div className="countries-cities-wrapper">
              {allFetchedCountries.map((country) => {
                return (
                  <div className="countries-cities-all-checkboxes">
                    {/* <Checkbox
                      className="check-all-checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValue(
                            'country_id',
                            checkBoxsArr(watch('country_id')).map(
                              (item) => item.value
                            )
                          );
                        } else setValue('country_id', []);
                        handleNavigateAll(
                          e.target.checked,
                          'country_id',
                          watch('country_id'),
                          navigate,
                          values
                        );
                      }}
                      checked={
                        watch('country_id')?.length ===
                        checkBoxsArr(watch('country_id'))?.length
                      }
                    >
                      {t('productsPage.checkAll')}
                    </Checkbox> */}

                    <div className="countries-cities-checkboxes">
                      <Checkbox
                        className="checkbox-item"
                        onChange={(e) => {
                          const old_values = watch('country_id');
                          if (old_values && old_values?.length >= 0) {
                            if (e.target.checked) {
                              setValue('country_id', [
                                ...old_values,
                                country.id.toString()
                              ]);
                            } else {
                              setValue('country_id', [
                                ...old_values.filter((v) => v != country.id)
                              ]);
                            }
                          }

                          handleNavigateSingleFilter(
                            'country_id',
                            watch('country_id'),
                            navigate,
                            values
                          );
                        }}
                        checked={watch('country_id')?.find(
                          (v) => v == country.id
                        )}
                      >
                        {country?.name}
                      </Checkbox>
                      {country &&
                        country?.cities &&
                        country?.cities?.length > 0 && (
                          <div className="cities-checkboxs-wrapper">
                            {country.cities.map((city) => {
                              return (
                                <Checkbox
                                  className="checkbox-item"
                                  onChange={(e) => {
                                    const old_values = watch('city_id');

                                    if (old_values && old_values?.length >= 0) {
                                      if (e.target.checked) {
                                        setValue('city_id', [
                                          ...old_values,
                                          city.id.toString()
                                        ]);
                                      } else {
                                        setValue('city_id', [
                                          ...old_values.filter(
                                            (v) => v != city.id
                                          )
                                        ]);
                                      }
                                    }

                                    handleNavigateSingleFilter(
                                      'city_id',
                                      watch('city_id'),
                                      navigate,
                                      values
                                    );
                                  }}
                                  checked={watch('city_id')?.find(
                                    (v) => v == city.id
                                  )}
                                >
                                  {city?.name}
                                </Checkbox>
                              );
                            })}
                          </div>
                        )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {allFetchedCats?.length > 0 && renderCatsFilter()}

          {allFetchedFilterCats?.length > 0 &&
            renderCheckboxsFilter(
              allFetchedFilterCats,
              filterTypes?.catFilter_id,
              t('productsPage.specificFor'),
              'specific-for-section'
            )}

          {discountOfferFilterArr(t)?.length > 0 && renderDiscountFilter()}

          {productSettingsOptions(t)?.length > 0 &&
            renderCheckboxsFilter(
              productSettingsOptions(t),
              filterTypes?.delivery,
              t('productsPage.delivery'),
              'delivery-section'
            )}

          {rateArr?.length > 0 && renderRateFilter()}

          {allFetchedPriceFilter?.length > 0 && renderPricesFilter()}

          <div className="shared-filter-section payment-section btns-section">
            <div className="section-title">
              {t('productsPage.paymentMethods')}
            </div>
            {paymentTypesFilter(t)?.length > 0 && (
              <div className="btns-wrap">
                {paymentTypesFilter(t).map((obj) => {
                  return (
                    <RouterLink
                      className={
                        values?.paymentType == obj?.id ? 'active-btn' : ''
                      }
                      to={`${
                        generalRouterLinks?.generalProductsRoute
                      }?store_id=${values?.store_id || ''}&country_id=${
                        values?.country_id || ''
                      }&city_id=${values?.city_id || ''}&cat_id=${
                        values?.cat_id || ''
                      }&catFilter_id=${values?.catFilter_id || ''}&discount=${
                        values?.discount || ''
                      }&delivery=${values?.delivery || ''}&rate=${
                        values?.rate || ''
                      }&fromPrice=${values?.fromPrice || ''}&toPrice=${
                        values?.toPrice || ''
                      }&paymentType=${
                        values?.paymentType == obj?.id ? '' : obj?.id || ''
                      }&sort=${values?.sort || ''}&page=${values?.page || 1}`}
                      onClick={() => {
                        setTableFilter((prev) => {
                          if (prev?.paymentType == obj?.id)
                            return {
                              ...prev,
                              store_id: values?.store_id || '',
                              paymentType: {
                                ...prev?.paymentType,
                                value: ''
                              },
                              page: 1
                            };

                          return {
                            ...prev,
                            store_id: values?.store_id || '',
                            paymentType: {
                              ...prev?.paymentType,
                              value: obj?.id
                            },
                            page: 1
                          };
                        });
                      }}
                      key={obj?.id}
                    >
                      {obj?.name}
                    </RouterLink>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default GeneralproductsFilter;
