import { WhatsAppOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MainAppBarProvider } from '../../contexts/main-app-bar-context/MainAppBarProvider';
import MainAppBar from '../main-app-bar/MainAppBar';
import MainAppFooter from '../main-app-footer/MainAppFooter';
// import ReactNotification from 'react-notifications-component';

import './Layout.scss';

const AppLayout = ({ children }) => {
  const { i18n } = useTranslation();
  // const { Header, Content, Footer, Sider } = AntdLayout;
  return (
    <div className={`app-layout app-${i18n.dir()}`}>
      <MainAppBarProvider>
        <MainAppBar />
      </MainAppBarProvider>

      <div className="layout-content">{children}</div>

      <MainAppFooter />
      {/* <ReactNotification className={i18n.dir()} /> */}
      <a
        className="whatsapp-app-link"
        href="https://15557063046.wa.pulse.is"
        target="_blank"
        rel="noreferrer"
      >
        <WhatsAppOutlined />
      </a>
    </div>
  );
};

export default AppLayout;
