/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import cameraImg from '../../../assets/imgs/icons/camera-icon.png';
import uploadImg from '../../../assets/imgs/icons/upload-icon.png';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import FileInput from '../../../common/file-input/FileInput';
import PinIcon from '../../../common/icons/PinIcon';
import RadioButtonEmpty from '../../../common/icons/RadioButtonEmpty';
import RadioButtonFilled from '../../../common/icons/RadioButtonFilled';
import UserIcon from '../../../common/icons/UserIcon';
import CustomMap from '../../../components/custom-map/CustomMap';
import { merchantPaymentTypes } from '../../../const-values/paymentTypes';
import SingleStoreContext from '../../../contexts/single-store-context/SingleStoreContext';
import DocTitleScrollTop from '../../../utils/DocTitleScrollTop';
import './CreateStoreStepOne.scss';

const CreateStoreStepOne = ({
  countries,
  selectedLocation,
  setSelecectedLocation,
  selectedAddress,
  setSelectedAddress
}) => {
  DocTitleScrollTop();

  const { i18n, t } = useTranslation();
  const sharedLabelTrans = (key) => t(`newStorePage.newStoreForm.${key}.label`);
  const {
    watch,
    formState: { errors },
    register,
    unregister,
    setValue,
    control
  } = useFormContext();

  const handleCityOptions = (countryId) => {
    if (countryId && countries?.length > 0) {
      const foundCountry = countries.find((c) => c.id == countryId);
      if (foundCountry && foundCountry.cities?.length > 0) {
        return foundCountry.cities.map((city) => ({
          title: city?.name,
          value: String(city?.id)
        }));
      }
    }
    return [];
  };

  const { fetchedSingleStoreDetails } = useContext(SingleStoreContext);

  return (
    <div className="stepper-step create-store-step-one">
      <div className="names-wrapper">
        <AntdTextField
          name="name_ar"
          type="text"
          placeholder={sharedLabelTrans('storeNameAr')}
          label={sharedLabelTrans('storeNameAr')}
          errorMsg={errors?.name_ar?.message}
          validateStatus={errors?.name_ar ? 'error' : ''}
          prefix={<UserIcon />}
          control={control}
        />
        <AntdTextField
          name="name_en"
          type="text"
          placeholder={sharedLabelTrans('storeNameEn')}
          label={sharedLabelTrans('storeNameEn')}
          errorMsg={errors?.name_en?.message}
          validateStatus={errors?.name_en ? 'error' : ''}
          prefix={<UserIcon />}
          control={control}
        />
      </div>

      <div
        className={`country-code-phone-wrap ${i18n.dir()}`}
        style={{
          marginBottom: 28
        }}
      >
        <p
          style={{
            paddingBottom: 8
          }}
        >
          {sharedLabelTrans('phone')}
        </p>
        <Controller
          name="phone"
          control={control}
          // render={({ field: { onChange, onBlur, value, name, ref } }) => {
          render={({ field }) => {
            return (
              <PhoneInput
                title="Phone"
                {...field}
                placeholder={sharedLabelTrans('phone')}
                // value={phoneValue}
                // onChange={setPhoneValue}
                // defaultCountry={!fetchedSingleStoreDetails ? 'SA' : }
                defaultCountry="SA"
                className={`custom-phone-input ${i18n.language}`}
              />
            );
          }}
        />
        <p className="error-p">{errors?.phone?.message}</p>
      </div>

      <div className="select-label-wrap">
        <p className="label-p">{sharedLabelTrans('country')}</p>
        <div className="custom-select-wrap without-icon">
          <AntdSelectOption
            name={`country_id`}
            errorMsg={errors?.country_id && errors.country_id.message}
            validateStatus={errors?.country_id ? 'error' : ''}
            control={control}
            setValue={setValue}
            placeholder={sharedLabelTrans('country')}
            prefix={<UserIcon />}
            options={
              countries?.length > 0 &&
              countries.map((obj) => ({
                title: obj?.name,
                value: String(obj?.id)
              }))
            }
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            formClassName="new-store-stepper-form"
          />
        </div>
      </div>

      <div className="select-label-wrap">
        <p className="label-p">{sharedLabelTrans('city')}</p>
        <div className="custom-select-wrap without-icon">
          <AntdSelectOption
            name={`city_id`}
            errorMsg={errors?.city_id && errors.city_id.message}
            validateStatus={errors?.city_id ? 'error' : ''}
            control={control}
            setValue={setValue}
            placeholder={sharedLabelTrans('city')}
            options={handleCityOptions(watch('country_id'))}
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            formClassName="new-store-stepper-form"
          />
        </div>
      </div>

      <AntdTextField
        name="area_ar"
        type="text"
        placeholder={sharedLabelTrans('area_ar')}
        label={sharedLabelTrans('area_ar')}
        errorMsg={errors?.area_ar?.message}
        validateStatus={errors?.area_ar ? 'error' : ''}
        prefix={<PinIcon />}
        control={control}
      />
      <AntdTextField
        name="area_en"
        type="text"
        placeholder={sharedLabelTrans('area_en')}
        label={sharedLabelTrans('area_en')}
        errorMsg={errors?.area_en?.message}
        validateStatus={errors?.area_en ? 'error' : ''}
        prefix={<PinIcon />}
        control={control}
      />
      <AntdTextField
        name="street_ar"
        type="text"
        placeholder={sharedLabelTrans('street_ar')}
        label={sharedLabelTrans('street_ar')}
        errorMsg={errors?.street?.message}
        validateStatus={errors?.street ? 'error' : ''}
        prefix={<PinIcon />}
        control={control}
      />
      <AntdTextField
        name="street_en"
        type="text"
        placeholder={sharedLabelTrans('street_en')}
        label={sharedLabelTrans('street_en')}
        errorMsg={errors?.street?.message}
        validateStatus={errors?.street ? 'error' : ''}
        prefix={<PinIcon />}
        control={control}
      />
      <AntdTextField
        name="buildingNumber"
        type="text"
        placeholder={sharedLabelTrans('buildingNumber')}
        label={sharedLabelTrans('buildingNumber')}
        errorMsg={errors?.buildingNumber?.message}
        validateStatus={errors?.buildingNumber ? 'error' : ''}
        prefix={<PinIcon />}
        control={control}
      />

      <div className="desc-wrapper">
        <div className="text-area-label-wrap">
          <p className="label-p">{sharedLabelTrans('descAr')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area white-bg"
              name="description_ar"
              rows={4}
              placeholder={sharedLabelTrans('descAr')}
              errorMsg={errors?.description_ar?.message}
              validateStatus={errors?.description_ar ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-area-label-wrap">
          <p className="label-p">{sharedLabelTrans('descEn')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area white-bg"
              name="description_en"
              rows={4}
              placeholder={sharedLabelTrans('descEn')}
              errorMsg={errors?.description_en?.message}
              validateStatus={errors?.description_en ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: 18,
          display: 'flex',
          gap: 12,
          fontSize: 18
        }}
        className="payments-wrap"
      >
        <div
          className="radios-wrap"
          style={{
            marginBottom: 28
          }}
        >
          <div className="labels-wrap need-label">
            {merchantPaymentTypes(t)?.length > 0 &&
              merchantPaymentTypes(t).map((obj) => (
                <label
                  key={obj?.id}
                  className={`radio-input-label ${i18n.dir()} ${
                    String(watch(`paymentType`)) == String(obj.id)
                      ? 'selected'
                      : ''
                  }`}
                >
                  <input
                    disabled={fetchedSingleStoreDetails}
                    onClick={() => {
                      if (watch('paymentType') == obj?.value) {
                        setValue('paymentType', '');
                      }
                    }}
                    type="radio"
                    value={obj?.value}
                    {...register(`paymentType`)}
                  />
                  {watch(`paymentType`) == obj?.value ? (
                    <RadioButtonFilled />
                  ) : (
                    // <CustomImage
                    //   className="need-img filled"
                    //   src={obj?.logo}
                    // />
                    <RadioButtonEmpty />
                    // <CustomImage className="need-img" src={obj?.logo} />
                  )}
                  <span className="label-span">{obj?.name}</span>
                </label>
              ))}
          </div>
          {errors?.paymentType && (
            <p
              className="error-p"
              style={{
                padding: '0 18px'
              }}
            >
              {errors?.paymentType?.message}
            </p>
          )}
        </div>

        {/* <AntdCheckbox
          name="isOnlinePayment"
          control={control}
          label={t('newStorePage.newStoreForm.onlinePayment')}
          errorMsg={errors?.isOnlinePayment?.message}
          validateStatus={errors?.isOnlinePayment ? 'error' : ''}
        />
        <AntdCheckbox
          name="isCashPayment"
          control={control}
          label={t('newStorePage.newStoreForm.cashPayment')}
          errorMsg={errors?.isCashPayment?.message}
          validateStatus={errors?.isCashPayment ? 'error' : ''}
        /> */}
      </div>

      <CustomMap
        width="100%"
        height="400px"
        isDraggable={true}
        hasAutoComplete={true}
        selectedLocation={selectedLocation}
        setSelecectedLocation={setSelecectedLocation}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
      />

      <div className="images-wrap">
        <FileInput
          name="storeFiles"
          label={sharedLabelTrans('storeDocs')}
          // accept="image/png, image/jpg, image/jpeg, image/gif"
          // accept="image/*"
          accept="image/*, application/*"
          multiple={true}
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          icon={<img src={uploadImg} alt="upload" />}
          // setUrls={setUrls}
          dropzoneText={t('newStorePage.newStoreForm.storeDocs.dropzone')}
          className="product-images-dropzone"
          dropzoneUrls={
            fetchedSingleStoreDetails?.storeFiles?.length > 0
              ? fetchedSingleStoreDetails.storeFiles.map((i) => ({
                  id: i.id,
                  url: i.file
                }))
              : []
          }
          canDelete={false}
          // handleDeleteImgReq={(id) => deleteImg(id)}
        />
      </div>

      <div className="cover-logo-wrap">
        <div className="logo-dropzone-wrap">
          <FileInput
            name="logo"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={sharedLabelTrans('storeLogo')}
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            // dropzoneText="اسحب الصورة وضعها هنا ..."
            icon={
              <img
                style={{
                  width: 52
                }}
                src={cameraImg}
                alt="upload"
              />
            }
            className="product-images-dropzone"
            dropzoneUrls={
              fetchedSingleStoreDetails?.logo
                ? [{ url: fetchedSingleStoreDetails.logo }]
                : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.logo?.message}
          />
        </div>

        <div className="cover-dropzone-wrap">
          <FileInput
            name="cover"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={sharedLabelTrans('storeCover')}
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            icon={<img src={uploadImg} alt="upload" />}
            // setUrls={setUrls}
            dropzoneText={t('newStorePage.newStoreForm.storeCover.dropzone')}
            className="product-images-dropzone"
            dropzoneUrls={
              fetchedSingleStoreDetails?.cover
                ? [{ url: fetchedSingleStoreDetails.cover }]
                : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.cover?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateStoreStepOne;
