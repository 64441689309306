/* eslint-disable eqeqeq */

import { EyeOutlined } from '@ant-design/icons';
import { Button, Tag, Tooltip } from 'antd';
import { transactionsStatusArr } from '../../../../../const-values/transactionsStatus';

const tableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n,
  t,
  ...props
) => {
  const renderStatus = (record) => {
    const foundObj = transactionsStatusArr(t).find(
      (obj) => obj.value === record.status
    );
    if (foundObj) return <Tag color={foundObj?.color}>{foundObj?.name}</Tag>;
    return '---';
  };
  return [
    {
      title: t('product'),
      width: 122,
      dataIndex: 'product',
      ellipses: true,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.product?.name ?? '---'}
          </div>
        );
      }
    },

    {
      title: t('client'),
      width: 82,
      dataIndex: 'client',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.user_id?.name ?? '---'}
          </div>
        );
      }
    },
    {
      title: t('reason'),
      width: 142,
      dataIndex: 'reason',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.reason ?? '---'}</div>
        );
      }
    },
    {
      title: t('view_replies'),
      dataIndex: 'details',
      width: 62,
      ellipses: true,
      // width: 192,
      render: (_, record) => {
        return (
          <Tooltip title={t('view_replies')}>
            <Button
              size="large"
              type="dashed"
              shape="round"
              style={{
                width: 42,
                height: 42,
                borderRadius: 50
              }}
              icon={<EyeOutlined />}
              onClick={() => {
                if (props?.length > 0 && props[0]?.setReportRepliesModal) {
                  props[0].setReportRepliesModal({
                    opened: true,
                    selected: record?.replays
                  });
                }
              }}
            />
          </Tooltip>
        );
      }
    }
    // {
    //   title: t('complaint_date'),
    //   width: 122,
    //   dataIndex: 'complaint_date',
    //   ellipses: true,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         {record.created_at
    //           ? dayjs(record.created_at).format('DD-MM-YYYY')
    //           : '---'}
    //       </div>
    //     );
    //   }
    // }
  ];
};

export default tableColumns;
