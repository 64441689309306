/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import * as Yup from 'yup';
import { forgetPasswordEnterEmailApi } from '../../apis/auth/forgetPassApis';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';

const ForgetPasswordFormEnterEmail = () => {
  const { t, i18n } = useTranslation();
  const sharedT = (key) => t(`forgetPasswordPage.${key}`);
  const generalTypeErrorStr = (v) => t(`signup_form.${v}.errors.type_error`);
  const generalEqualErrorStr = (v, equals) =>
    t(`forgetPasswordPage.${v}.errors.equals`, {
      equals
    });
  const schema = Yup.object().shape({
    // email: Yup.string()
    //   .required(sharedT('email.errors.required'))
    //   .email(sharedT('email.errors.typeError'))
    phone: Yup.string()
      // .typeError(generalTypeErrorStr('phone'))
      .required(sharedT('phone.errors.required'))
      // .matches(/^[0-9]+$/, generalTypeErrorStr('phone'))
      .test('phone', generalEqualErrorStr('phone', 10), (v) => {
        return v && String(v)?.length === 13;
      })
  });

  const {
    setForgetPasswordFormEnterEmailAppended,
    setForgetPasswordFormEnterCodeAppended,
    setUser
  } = useContext(ForgetPasswordContext);
  const {
    control,
    handleSubmit,
    reset,
    // watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      email: ''
    }
  });

  React.useEffect(() => {
    return () => {
      reset({ email: '' });
    };
  }, []);
  const [isLoading, setIsLoading] = React.useState(false);
  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    setIsLoading(true);
    const formatedIntlPhone = formatPhoneNumberIntl(`${data.phone}`); // '+966 50 123 4444'
    const phoneStrArr = formatedIntlPhone.split(' '); // ['+966', '50', '123', '4444']
    const countryCode = phoneStrArr[0];
    let phone = '';
    if (phoneStrArr?.length === 2) phone = phoneStrArr[1];
    else if (phoneStrArr?.length > 2) {
      let array = phoneStrArr.filter((item, index) => index > 0); // ['50', '123', '4444']
      phone = array.join('');
    }
    customApiRequest(
      forgetPasswordEnterEmailApi({ ...data, phone, countryCode }),
      (res) => {
        setIsLoading(false);
        if (checkRes(res)) {
          successNotification({
            title: t('successTitle'),
            message: t('forgetPasswordPage.codeSent')
          });
          setForgetPasswordFormEnterEmailAppended(false);
          setForgetPasswordFormEnterCodeAppended(true);
          setUser(res?.data?.data);
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
          });
        }
      },
      (error) => {
        setIsLoading(false);
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message || 'حاول فى وقت لاحق'
        });
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        {/* <p className="label-p">البريد الاكترونى</p> */}
        {/* <AntdTextField
          // className="form-text-field"
          label={sharedT('email.label')}
          name="email"
          type="text"
          placeholder={sharedT('email.label')}
          errorMsg={errors?.email?.message}
          validateStatus={errors?.email ? 'error' : ''}
          control={control}
          prefix={<MailOutlined />}
        /> */}

        <div
          className={`country-code-phone-wrap ${i18n.dir()}`}
          style={{
            marginBottom: 28
          }}
        >
          <p
            style={{
              paddingBottom: 8
            }}
          >
            {sharedT('phone.label')}
          </p>
          <Controller
            name="phone"
            control={control}
            // render={({ field: { onChange, onBlur, value, name, ref } }) => {
            render={({ field }) => {
              return (
                <PhoneInput
                  {...field}
                  placeholder={sharedT('phone.label')}
                  // value={phoneValue}
                  // onChange={setPhoneValue}
                  defaultCountry="SA"
                  className={`custom-phone-input ${i18n.language}`}
                />
              );
            }}
          />
          <p className="error-p">{errors?.phone?.message}</p>
        </div>

        {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isLoading}
        >
          {sharedT('send')}
        </Button>
      </div>
    </Form>
  );
};

export default ForgetPasswordFormEnterEmail;
