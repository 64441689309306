import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import signupApi from '../apis/auth/signupApi';
import routerLinks from '../components/app/routerLinks';
import UserContext from '../contexts/user-context/UserProvider';
import errorNotification from '../utils/errorNotification';
import successNotification from '../utils/successNotification';
import useCustomApiRequest from './useCustomApiRequest';

const useSignupEmailPassword = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { setUserToState, setCurrentUser } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const [isLoadingSignup, setIsLoadingSignup] = React.useState(false);
  const formData = new FormData();
  const signMeUpWithEmailPassword = (data) => {
    setIsLoadingSignup(true);
    if (data.accountType) formData.append('type', data.accountType);
    if (data.name) formData.append('name', data.name);
    if (data.phone) formData.append('phone', data.phone);
    if (data.email) formData.append('email', data.email);
    if (data.password) formData.append('password', data.password);
    if (data.saloonName) formData.append('saloonName_ar', data.saloonName);
    if (data.saloonName) formData.append('saloonName_en', data.saloonName);
    if (data.saloonDescription_ar)
      formData.append('saloonDescription_ar', data.saloonDescription_ar);
    if (data.saloonDescription_en)
      formData.append('saloonDescription_en', data.saloonDescription_en);
    if (data.area) formData.append('neighborhood_ar', data.area);
    if (data.area) formData.append('neighborhood_en', data.area);
    if (data.street) formData.append('street_ar', data.street);
    if (data.street) formData.append('street_en', data.street);
    if (data.buildingNumber)
      formData.append('buildingNumber', data.buildingNumber);
    if (data.country_id) formData.append('country_id', data.country_id);
    if (data.city_id) formData.append('city_id', data.city_id);
    if (data.lat) formData.append('saloonLat', data.lat);
    if (data.lng) formData.append('saloonLng', data.lng);
    if (data.saloonFiles?.length > 0) {
      for (let ele of data.saloonFiles) formData.append('saloonFiles[]', ele);
    }
    customApiRequest(
      signupApi(formData, i18n.language),
      (res) => {
        setIsLoadingSignup(false);
        if (res?.data?.status === 1) {
          if (res?.data?.data?.status === 0) {
            successNotification({
              title: t('successTitle'),
              message: 'ادخل الكود المرسل الى رقم الجوال'
            });
            setUserToState({
              ...res.data.data,
              accountType: data?.accountType,
              firstTime: false,
              activated: false
            });
            navigate(routerLinks?.activateAccountRoute);
          } else if (res?.data?.data?.status === 1) {
            successNotification({
              title: t('successTitle'),
              message: 'تم تسجيل دخولك بنجاح'
            });
            setCurrentUser({
              ...res.data.data,
              firstTime: false,
              accountType: data?.accountType,
              activated: true
            });
          }
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
          });
        }
      },

      (error) => {
        setIsLoadingSignup(false);
        errorNotification({
          title: 'حدث خطأ اثناء إنشاء الحساب',
          message: error?.response?.data?.message || 'من فضلك حاول وقت لاحق'
        });
      }
    );
  };

  return { signMeUpWithEmailPassword, isLoadingSignup, setIsLoadingSignup };
};

export default useSignupEmailPassword;
