/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { PercentageOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import createCouponApi from '../../../apis/auth/createCouponApi';
import updateCouponApi from '../../../apis/auth/updateCouponApi';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import RadioButtonEmpty from '../../../common/icons/RadioButtonEmpty';
import RadioButtonFilled from '../../../common/icons/RadioButtonFilled';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import discountCodeTypes, {
  discountTypesOptions
} from '../../../const-values/discountCodeTypes';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import i18n from '../../../i18n';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import couponFormSchema from './couponFormSchema';
import './CreateCouponForm.scss';

const CreateCouponForm = ({
  modalOpened,
  setModalOpened,
  selectedCoupon,
  setSelectedCoupon,
  incrementFetchCount
}) => {
  // const [urls, setUrls] = React.useState([]);
  const { user } = useContext(UserContext);

  const schema = couponFormSchema(selectedCoupon, t);
  const {
    setValue,
    handleSubmit,
    watch,
    register,
    unregister,
    setError,
    clearErrors,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      code: '',
      discount_type: '1', // 1 => fixed type , 2 => percentage
      amount: '',
      expire_date: ''
    }
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // handle initial values
  useEffect(() => {
    if (selectedCoupon) {
      setValue('code', selectedCoupon.code || '');
      setValue(
        'discount_type',
        selectedCoupon?.discount ? String(selectedCoupon.codeType) : ''
      );
      setValue('amount', selectedCoupon.amount ?? '');
      setValue('discount_type', selectedCoupon.amount_type ?? '');
      setValue('codeValue', selectedCoupon.codeValue);
      if (selectedCoupon?.expire_date) {
        setValue(
          'expire_date',
          dayjs(selectedCoupon.expire_date, 'YYYY-MM-DD')
        );
      }
    }
  }, [selectedCoupon, i18n.language]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    const formData = new FormData();
    if (data.expire_date)
      formData.append(
        'expire_date',
        dayjs(data.expire_date).format('YYYY-MM-DD')
      );
    formData.append('amount', data.amount);
    formData.append('code', data.code);
    formData.append('discount_type', data.discount_type);
    setIsSubmitting(true);
    if (!selectedCoupon) {
      customApiRequest(
        createCouponApi(user?.token, formData, i18n.language),
        (res) => {
          setIsSubmitting(false);
          if (checkRes(res)) {
            setSelectedCoupon(null);
            setModalOpened(false);
            incrementFetchCount();
            successNotification({
              title: t('successTitle'),
              message: 'تمت الإضافة بنجاح'
            });
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmitting(false);
          errorNotification({
            title: t('errorTitle'),
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      formData.append('coupon_id', selectedCoupon.id);
      customApiRequest(
        updateCouponApi(user?.token, formData, i18n.language, true),
        (res) => {
          setIsSubmitting(false);
          if (checkRes(res)) {
            setSelectedCoupon(null);
            setModalOpened(false);
            incrementFetchCount();
            successNotification({
              title: t('successTitle'),
              message: 'تم التعديل بنجاح'
            });
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmitting(false);
          errorNotification({
            title: t('errorTitle'),
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();

  const baseLabelTransString = (t, key) => t(`coupon_form.${key}.label`);

  const renderCodeDiscountOptions = () => {
    return (
      <div className="codes-radios-inputs-wrapper">
        <div className="radios-wrap">
          {/* <p className="radios-group-title">إختار طريقة استرجاع المنتج</p> */}
          <div className="labels-wrap">
            {discountTypesOptions(t)?.length > 0 &&
              discountTypesOptions(t).map((obj, i) => (
                <label
                  key={i}
                  className={`radio-input-label ${
                    String(watch(`discount_type`)) === String(obj.value)
                      ? 'selected'
                      : ''
                  }`}
                >
                  <input
                    type="radio"
                    value={obj.value}
                    {...register(`discount_type`)}
                    onChange={(e) => {
                      register('discount_type').onChange(e);
                      setValue('amount', '');
                    }}
                  />
                  {watch(`discount_type`) == String(obj.value) ? (
                    <RadioButtonFilled />
                  ) : (
                    <RadioButtonEmpty />
                  )}
                  <span className="label-span">{obj.title}</span>
                </label>
              ))}
          </div>
          {errors?.discount_type && (
            <p
              className="error-p"
              style={{
                marginTop: 6
              }}
            >
              {errors?.discount_type?.message}
            </p>
          )}
        </div>

        {watch('discount_type') && (
          <AntdTextField
            className="code-input-field"
            name="amount"
            type="text"
            placeholder={
              watch('discount_type') == discountCodeTypes.fixedValue
                ? baseLabelTransString(t, 'fixedValue')
                : baseLabelTransString(t, 'percentageValue')
            }
            label={
              watch('discount_type') == discountCodeTypes.fixedValue
                ? baseLabelTransString(t, 'fixedValue')
                : baseLabelTransString(t, 'percentageValue')
            }
            errorMsg={errors?.amount?.message}
            validateStatus={errors?.amount ? 'error' : ''}
            prefix={
              watch('discount_type') == discountCodeTypes.fixedValue ? (
                t('currency.sar')
              ) : (
                <PercentageOutlined />
              )
            }
            control={control}
          />
        )}

        <label className="custom-date-input coupon-date-label">
          <span className="label-span">
            {baseLabelTransString(t, 'expire_date')}
          </span>
          <CustomAntdDatePicker
            disablePast
            control={control}
            name={`expire_date`}
            placeholder={baseLabelTransString(t, 'expire_date')}
            className="order-discount-picker"
            formClassName="create-product-form"
            errorMsg={errors?.expire_date && errors.expire_date.message}
          />
        </label>
      </div>
    );
  };

  return (
    <Form
      className="create-coupon-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <AntdTextField
          className="code-input-field"
          name="code"
          type="text"
          placeholder={baseLabelTransString(t, 'code')}
          label={baseLabelTransString(t, 'code')}
          errorMsg={errors?.code?.message}
          validateStatus={errors?.code ? 'error' : ''}
          prefix={'#'}
          control={control}
        />
        {renderCodeDiscountOptions()}

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmitting}
        >
          {selectedCoupon ? 'تعديل' : 'إضافة'}
        </Button>
      </div>

      {isSubmitting && <LoadingModal />}
    </Form>
  );
};

export default CreateCouponForm;
