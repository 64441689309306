/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import queryString from 'query-string';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import AntdCheckboxsGroup from '../../../common/antd-form-components/AntdCheckboxsGroup';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import filterTypes from '../../../const-values/filterTypes';
import MerchantModelsContext from '../../../contexts/merchant-models-context/MerchantModelsContext';
import {
  handleNavigateAll,
  handleNavigateSingleFilter
} from './handleNavigateCheckboxsFilter';
import './ModelsFilter.scss';

const ModelsFilter = ({ allFetchedCats, allFetchedFilterCats }) => {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { setTableFilter } = useContext(MerchantModelsContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const schema = Yup.object().shape({
    // loginKey: Yup.string()
    //   .required('من فضلك ادخل البريد الاكترونى')
    //   .email('ادخل بريد الكترونى صحيح'),
  });
  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      catFilter_id: []
    }
  });

  // const onSubmit = (data) => {
  //     signMeInWithEmailPassword({
  //       // email: data.loginKey,
  //       ...data
  //     });
  //   };

  const checkBoxsArr = (arr) => {
    let items = arr.map((item) => {
      return {
        label: item?.name,
        value: item?.id
      };
    });
    return [
      // {
      //   label: 'all',
      //   value: 'all'
      // },
      ...items
    ];
  };

  useEffect(() => {
    if (watch('catFilter_id')?.length >= 0) {
      setTableFilter((prev) => {
        return {
          ...prev,
          cat_id: {
            ...prev?.cat_id,
            value: values?.cat_id || ''
          },
          catFilter_id: {
            ...prev?.catFilter_id,
            value: watch('catFilter_id') || []
          }
        };
      });
    }
  }, [watch('catFilter_id'), search]);

  // handle initial values
  useEffect(() => {
    setValue(
      'catFilter_id',
      values?.catFilter_id
        ? values?.catFilter_id.split(',')?.length > 0 &&
            values.catFilter_id.split(',').map((item) => parseInt(item))
        : []
    );

    // }
  }, [values?.catFilter_id]);

  const renderCheckboxsFilter = (arr, name, title, className) => {
    return (
      <div className={`shared-filter-section ${className}`}>
        <div className="section-title bold-body-font">{title}</div>
        <div className="section-checkboxs-wrap">
          <Checkbox
            className="check-all-checkbox"
            onChange={(e) => {
              if (e.target.checked) {
                setValue(
                  name,
                  checkBoxsArr(arr).map((item) => item.value)
                );
              } else setValue(name, []);
              handleNavigateAll(e.target.checked, name, arr, navigate, values);
            }}
            checked={watch(name)?.length === checkBoxsArr(arr)?.length}
          >
            {t('productsPage.checkAll')}
          </Checkbox>

          <AntdCheckboxsGroup
            name={name}
            // label="label here"
            className="models-filter-form"
            // onChange={() => setValue('model_id', '')}
            onChange={(v) => {
              handleNavigateSingleFilter(name, v, navigate, values);
            }}
            control={control}
            validateStatus={errors?.name ? 'error' : ''}
            errorMsg={errors?.name?.message}
            options={arr?.length > 0 ? [...checkBoxsArr(arr)] : []}
            // defaultValue={
            //   values?.airline_id ? values?.airline_id.split(',') : []
            // }
          />
        </div>
      </div>
    );
  };

  const renderCatsFilter = () => {
    return (
      <div className="shared-filter-section cats-section btns-section">
        <div className="section-title">{t('productsPage.categories')}</div>

        <div className="shared-filter-list-wrapper">
          <div className="shared-filter-scrollable-wrapper">
            <div className="scrollable-inner">
              {allFetchedCats?.length > 0 && (
                <div className="btns-wrap">
                  <div className="btns-wrapper">
                    {allFetchedCats.map((cat) => {
                      return (
                        <RouterLink
                          className={
                            values?.cat_id == cat?.id ? 'active-btn' : ''
                          }
                          to={`${merchentRouterLinks.merchantModels}?cat_id=${
                            values?.cat_id == cat?.id ? '' : cat?.id || ''
                          }&catFilter_id=${values?.catFilter_id || ''}&page=${
                            values?.page || 1
                          }`}
                          onClick={() => {
                            setTableFilter((prev) => {
                              if (prev?.cat_id == cat?.id) {
                                return {
                                  ...prev,
                                  page: 1,
                                  cat_id: {
                                    ...prev?.cat_id,
                                    value: ''
                                  }
                                };
                              }
                              return {
                                ...prev,
                                store_id: values?.store_id || '',
                                page: 1,
                                cat_id: {
                                  ...prev?.cat_id,
                                  value: cat?.id
                                }
                              };
                            });
                          }}
                          key={cat?.id}
                        >
                          {cat?.name}
                        </RouterLink>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [form] = Form.useForm();
  return (
    <div className="models-filter-lg">
      <div className="filter-content">
        <div className="filter-scroll-container">
          <p className="filter-wrapper-label">{t('filter_results')}</p>
          <Form
            className="models-filter-form"
            form={form}
            layout="vertical"
            // onFinish={handleSubmit(onSubmit)}
            initialValues={{
              catFilter_id: watch('catFilter_id')
            }}
            fields={[
              {
                name: 'catFilter_id',
                value: watch('catFilter_id')
              }
            ]}
          >
            <div className="form-body">
              {allFetchedCats?.length > 0 && renderCatsFilter()}

              {allFetchedFilterCats?.length > 0 &&
                renderCheckboxsFilter(
                  allFetchedFilterCats,
                  filterTypes?.catFilter_id,
                  t('productsPage.specificFor'),
                  'specific-for-section'
                )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ModelsFilter;
