/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import createNewStoreApi from '../../../apis/stores-apis/createNewStoreApi';
import ReactHookFormStepper from '../../../common/react-hook-form-stepper/ReactHookFormStepper';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import SingleStoreContext from '../../../contexts/single-store-context/SingleStoreContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCountries from '../../../custom-hooks/useCountries';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import CreateStoreStepOne from './CreateStoreStepOne';
import CreateStoreStepTwo from './CreateStoreStepTwo';
import newStoreDefaultValues from './newStoreDefaultValues';
import './NewStoreStepperForm.scss';

const NewStoreStepperForm = ({ step, setStep, edit = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const sharedErrTrans = (key, errorType) =>
    t(`newStorePage.newStoreForm.${key}.errors.${errorType}`);
  const { i18n } = useTranslation();
  const { user } = React.useContext(UserContext);
  const [selectedLocation, setSelecectedLocation] = React.useState({
    lat: '',
    lng: ''
  });
  const [selectedAddress, setSelectedAddress] = React.useState('');
  const params = useParams();
  const {
    fetchedSingleStoreDetails,
    isLoading,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(SingleStoreContext);
  const { allFetchedCountries } = useCountries();

  const customApiRequest = useCustomApiRequest();

  const [fetchedStoreDefaultValues, setFetchedStoreDefaultValues] = useState({
    ...newStoreDefaultValues
  });

  // handle initial values
  useEffect(() => {
    if (fetchedSingleStoreDetails) {
      setSelecectedLocation({
        lat: fetchedSingleStoreDetails.lat
          ? parseFloat(fetchedSingleStoreDetails.lat)
          : '',
        lng: fetchedSingleStoreDetails.lng
          ? parseFloat(fetchedSingleStoreDetails.lng)
          : ''
      });
      setFetchedStoreDefaultValues({
        name_ar: fetchedSingleStoreDetails.name_ar || '',
        name_en: fetchedSingleStoreDetails.name_en || '',
        phone: fetchedSingleStoreDetails?.countryCode
          ? `${fetchedSingleStoreDetails.countryCode}${fetchedSingleStoreDetails.phone}`
          : fetchedSingleStoreDetails?.phone,
        country_id:
          fetchedSingleStoreDetails?.country_id &&
          allFetchedCountries?.length > 0
            ? String(fetchedSingleStoreDetails.country_id)
            : '',
        city_id:
          fetchedSingleStoreDetails?.city_id && allFetchedCountries?.length > 0
            ? String(fetchedSingleStoreDetails.city_id)
            : '',
        area_ar: fetchedSingleStoreDetails?.area_ar || '',
        area_en: fetchedSingleStoreDetails?.area_en || '',
        street_ar: fetchedSingleStoreDetails?.street_ar || '',
        street_en: fetchedSingleStoreDetails?.street_en || '',
        buildingNumber: fetchedSingleStoreDetails?.buildingNumber || '',
        // lat & lng
        description_ar: fetchedSingleStoreDetails.description_ar || '',
        description_en: fetchedSingleStoreDetails.description_en || '',
        paymentType: fetchedSingleStoreDetails
          ? String(fetchedSingleStoreDetails.paymentType)
          : '',
        bankName: fetchedSingleStoreDetails.bank?.name,
        shortNum: fetchedSingleStoreDetails.bank?.shortNum,
        longNum: fetchedSingleStoreDetails.bank?.longNum,
        bankCountry_id: fetchedSingleStoreDetails?.bank?.country_id
          ? String(fetchedSingleStoreDetails.bank.country_id)
          : '',
        bankCity_id: fetchedSingleStoreDetails?.bank?.city_id
          ? String(fetchedSingleStoreDetails.bank.city_id)
          : ''
      });
    }
  }, [fetchedSingleStoreDetails, allFetchedCountries]);

  const handleSubmit = (values) => {
    const formData = new FormData();
    values.name_ar && formData.append('name_ar', values.name_ar);
    values.name_en && formData.append('name_en', values.name_en);
    const formatedIntlPhone = formatPhoneNumberIntl(`${values.phone}`); // '+966 50 123 4444'
    const phoneStrArr = formatedIntlPhone.split(' '); // ['+966', '50', '123', '4444']
    const countryCode = phoneStrArr[0];
    let phone = '';
    if (phoneStrArr?.length === 2) phone = phoneStrArr[1];
    else if (phoneStrArr?.length > 2) {
      let array = phoneStrArr.filter((item, index) => index > 0); // ['50', '123', '4444']
      phone = array.join('');
    }
    if (values.phone) formData.append('phone', phone);
    if (values.phone) formData.append('countryCode', countryCode);
    values.description_ar &&
      formData.append('description_ar', values.description_ar);
    values.description_en &&
      formData.append('description_en', values.description_en);
    values.country_id && formData.append('country_id', values.country_id);
    values.city_id && formData.append('city_id', values.city_id);
    values.area_ar && formData.append('area_ar', values.area_ar);
    values.area_en && formData.append('area_en', values.area_en);
    values.street_ar && formData.append('street_ar', values.street_ar);
    values.street_en && formData.append('street_en', values.street_en);
    values.buildingNumber &&
      formData.append('buildingNumber', values.buildingNumber);
    selectedLocation?.lat && formData.append('lat', selectedLocation?.lat);
    selectedLocation?.lng && formData.append('lng', selectedLocation?.lng);
    values.cover?.length > 0 && formData.append('cover', values.cover[0]);
    values.logo?.length > 0 && formData.append('logo', values.logo[0]);
    if (values?.storeFiles?.length > 0)
      for (let ele of values.storeFiles) formData.append('storeFiles[]', ele);
    formData.append('paymentType', values?.paymentType);
    formData.append('bankName', values.bankName);
    formData.append('shortNum', values.shortNum);
    formData.append('longNum', values.longNum);
    formData.append('bankCountry_id', values.bankCountry_id);
    formData.append('bankCity_id', values.bankCity_id);

    if (!fetchedSingleStoreDetails && !edit) {
      setIsSubmittingForm(true);
      customApiRequest(
        createNewStoreApi(formData, user?.token, i18n.language),
        (res) => {
          setIsSubmittingForm(false);
          if (checkRes(res)) {
            successNotification({
              title: t('successTitle'),
              message: 'تم انشاء المتجر بنجاح'
            });
            setStep(2);
            // navigate(merchentRouterLinks?.merchentStores);
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data.message
                ? res.data.message
                : 'من فضلك حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          errorNotification({
            title: t('errorTitle'),
            message: error?.response?.data?.message
              ? error.response.data.message
              : 'من فضلك حاول فى وقت لاحق'
          });
        }
      );
    } else {
      formData.append('store_id', params?.store_id);
      setIsSubmittingForm(true);
      customApiRequest(
        createNewStoreApi(formData, user?.token, i18n.language, true),
        (res) => {
          if (checkRes(res)) {
            setIsSubmittingForm(false);
            successNotification({
              title: t('successTitle'),
              message: 'تمت الاضافة بنجاح'
            });
            navigate(merchentRouterLinks?.merchentStores);
          } else {
            setIsSubmittingForm(false);
            errorNotification({
              title: t('errorTitle'),
              message: res?.data.message
                ? res.data.message
                : 'من فضلك حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          errorNotification({
            title: t('errorTitle'),
            message: error?.response?.data?.message
              ? error.response.data.message
              : 'من فضلك حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  if (isLoading) {
    return (
      <div
        className="custom-container"
        style={{
          marginTop: 92,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Spin />
      </div>
    );
  }
  return (
    <ReactHookFormStepper
      step={step}
      setStep={setStep}
      className="new-store-stepper-form"
      defaultValues={newStoreDefaultValues}
      fetchedDefaultValues={fetchedStoreDefaultValues}
      // defaultValues={
      //   fetchedSingleStoreDetails
      //     ? fetchedStoreDefaultValues
      //     : newStoreDefaultValues
      // }
      isSubmittingForm={isSubmittingForm}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      <CreateStoreStepOne
        validationSchema={Yup.object().shape({
          name_ar: Yup.string().required(
            sharedErrTrans('storeNameAr', 'required')
          ),
          name_en: Yup.string().required(
            sharedErrTrans('storeNameEn', 'required')
          ),
          phone: Yup.string()
            // .typeError(t('newStorePage.newStoreForm.phone.errors.type_error'))
            .required(t('newStorePage.newStoreForm.phone.errors.required'))
            // .matches(/^[0-9]+$/, generalTypeErrorStr('phone'))
            .test(
              'phone',
              t('newStorePage.newStoreForm.phone.errors.equals', {
                equals: 10
              }),
              (v) => {
                return v && String(v)?.length === 13;
              }
            ),
          country_id: Yup.string().required(
            sharedErrTrans('country', 'required')
          ),
          city_id: Yup.string().required(sharedErrTrans('city', 'required')),
          description_ar: Yup.string().required(
            sharedErrTrans('descAr', 'required')
          ),
          description_en: Yup.string().required(
            sharedErrTrans('descEn', 'required')
          ),
          // area: Yup.string().required(sharedErrTrans('area', 'required')),
          // street: Yup.string().required(sharedErrTrans('street', 'required')),
          buildingNumber: Yup.string().required(
            sharedErrTrans('buildingNumber', 'required')
          )
        })}
        countries={allFetchedCountries}
        selectedLocation={selectedLocation}
        setSelecectedLocation={setSelecectedLocation}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        step={0}
      />
      <CreateStoreStepTwo
        countries={allFetchedCountries}
        step={1}
        validationSchema={Yup.object().shape({
          bankName: Yup.string().required(
            sharedErrTrans('bankName', 'required')
          ),
          bankCountry_id: Yup.string().required(
            sharedErrTrans('bankCountry', 'required')
          ),
          bankCity_id: Yup.string().required(
            sharedErrTrans('bankCity', 'required')
          ),
          shortNum: Yup.number()
            .required(sharedErrTrans('shortNumber', 'required'))
            .typeError(sharedErrTrans('shortNumber', 'onlyNumbers')),
          longNum: Yup.string()
            .required(sharedErrTrans('longNumber', 'required'))
            .matches(
              /^[a-zA-Z0-9]*$/i,
              sharedErrTrans('longNumber', 'typeError')
            )
        })}
      />
      {/* <CreateStoreStepThree step={2} /> */}

      {/* <CreateOrganizationStepTwo stepTitle="Contact Info" step={1} />

        <CreateOrganizationStepThree stepTitle="Staff" step={2} /> */}
    </ReactHookFormStepper>
  );
};

export default NewStoreStepperForm;
