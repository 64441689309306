/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import emptyImg from '../../../assets/imgs/empty/empty.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import EmptyCompoent from '../../../common/empty-compoent/EmptyComponent';
import NewProductIcon from '../../../common/icons/store-card-icons/NewProductIcon';
import routerLinks from '../../../components/app/routerLinks';
import useMerchantCoupons from '../../../custom-hooks/useMerchantCoupons';
import CreateCouponFormModal from './CreateCouponFormModal';
import MerchantCouponCard from './MerchantCouponCard';
import './MerchantCouponsPage.scss';

const MerchantCouponsPage = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const sharedTrans = (key) => t(`merchentDashboard.${key}`);
  const { isLoadingData, allFetchedTableData, setFetchTableDataCount } =
    useMerchantCoupons();
  const [copied, setCopied] = useState(false);
  const [copyCount, setCopyCount] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  useEffect(() => {
    if (copied && copyCount) {
      notification.success({
        message: t('copied'),
        duration: 1.5,
        onClick: () => {
          // console.log('Notification Clicked!');
        }
      });
    }
  }, [copied, copyCount]);

  const renderPageContent = () => {
    if (isLoadingData) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    } else if (allFetchedTableData?.length === 0) {
      return <EmptyCompoent img={emptyImg} />;
    } else if (allFetchedTableData?.length > 0) {
      return (
        <div className="page-content-wrapper">
          <div className="custom-container">
            <div className="list-wrapper">
              {allFetchedTableData.map((card, idx) => (
                <MerchantCouponCard
                  setModalOpened={setModalOpened}
                  setSelectedCoupon={setSelectedCoupon}
                  key={idx}
                  card={card}
                  setCopied={setCopied}
                  setCopyCount={setCopyCount}
                />
              ))}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="merchant-coupons-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: routerLinks.merchentDashboard
          },
          {
            title: t('merchentDashboard.saloon_coupons'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-create-coupon-btn-wrap">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">
              {t('merchentDashboard.saloon_coupons')}
            </div>
          </div>
          <button
            className="create-coupon-btn"
            onClick={() => {
              setSelectedCoupon(null);
              setModalOpened(true);
            }}
          >
            <NewProductIcon />
            {t('coupon_form.create')}
          </button>
        </div>
        <div className="page-body">{renderPageContent()}</div>
      </div>

      <CreateCouponFormModal
        incrementFetchCount={() => setFetchTableDataCount((prev) => prev + 1)}
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        selectedCoupon={selectedCoupon}
        setSelectedCoupon={setSelectedCoupon}
      />
    </div>
  );
};

export default MerchantCouponsPage;
